import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

import { getApps, initializeApp } from "firebase/app";
import {
  connectAuthEmulator,
  getAuth,
  GoogleAuthProvider,
} from "firebase/auth";
import firebase from "firebase/compat/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_API_KEY,
  appId: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_APP_ID,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_DATABASE_URL,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_MEASUREMENT_ID,
  messagingSenderId:
    process.env.NEXT_PUBLIC_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_STORAGE_BUCKET,
};

let app, compatApp: firebase.app.App;
if (getApps()) {
  app = initializeApp(firebaseConfig);
  compatApp = firebase.initializeApp(firebaseConfig, "compat");
}

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const compatAuth = compatApp.auth();
const db = getFirestore(app);
const compatDB = compatApp.firestore();
const functions = getFunctions(app);
const compatFunctions = compatApp.functions();
const storage = getStorage(app);
const compatStorage = compatApp.storage();

if (process.env.NEXT_PUBLIC_FIREBASE_USE_EMULATOR === "true") {
  connectAuthEmulator(auth, "http://localhost:9099");
  compatAuth.useEmulator("http://localhost:9099");

  connectFirestoreEmulator(db, "localhost", 8080);
  compatDB.useEmulator("localhost", 8080);

  connectStorageEmulator(storage, "localhost", 9199);
  compatStorage.useEmulator("localhost", 9199);

  connectFunctionsEmulator(functions, "localhost", 5001);
  compatFunctions.useEmulator("localhost", 5001);
}

export {
  app,
  auth,
  compatApp,
  compatAuth,
  compatDB,
  compatFunctions,
  compatStorage,
  db,
  functions,
  googleProvider,
  storage,
};
