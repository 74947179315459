import { UpdateData } from "firebase/firestore";
import { useDocument } from "swr-firebase";

import { AccountType } from "@lib/data/schemas/account";

type UseAccountType = (accountId: string | undefined | null) => {
  account: AccountType | undefined;
  loading: boolean;
  exists?: boolean;
  update: (data: UpdateData<AccountType>) => Promise<void> | null;
};

const useAccount: UseAccountType = (accountId) => {
  const { data, loading, update } = useDocument<AccountType>(
    accountId ? `accounts/${accountId}` : null,
    { listen: true }
  );

  return {
    account: data?.exists ? data : undefined,
    loading: accountId ? loading : false,
    exists: data?.exists,
    update,
  };
};

export default useAccount;
