export enum FeatureNames {
  customDomains = "custom-domains",
  digitalSignature = "digital-signature",
  whiteLabel = "white-label",
  zapier = "zapier",
  members = "members",
  unlimitedMembers = "unlimited-members",
  memberPermissions = "member-permissions",
  companyProfile = "company-profile",
  roundRobinScheduling = "round-robin-scheduling",
  accountsPayable = "accounts-payable",
  usageBasedPackage = "usage-based-package",
}
