import { z } from "zod";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";
import { InvoiceCustomFieldSchema } from "./invoice";
import { SubscriptionPlanTierEnum } from "./subscription-plan";

const UserInvoiceCustomizationSchema = z.object({
  memo: z.string().optional(),
  footer: z.string().optional(),
  customFields: z.array(InvoiceCustomFieldSchema).optional(),
});

export type UserInvoiceCustomizationType = z.infer<
  typeof UserInvoiceCustomizationSchema
>;

export const UserFontEnum = z.enum(["Modern", "Classic", "Rounded"]);

export type UserFontEnumType = z.infer<typeof UserFontEnum>;

const TestimonialSchema = z.object({
  name: z.string().optional(),
  text: z.string().optional(),
  clientId: z.string().optional(),
});

export type TestimonialType = z.infer<typeof TestimonialSchema>;

const ArtifactIdsOptionsSchema = z.array(
  z.object({
    value: z.string().optional(),
  })
);

const UserAccountStatusEnum = z.enum(["active", "paused"]);
const UserSubscriptionStatusEnum = z.enum([
  "active",
  "invalid-payment",
  "paused",
  "canceled",
]);

export type UserSubscriptionStatus = z.infer<typeof UserSubscriptionStatusEnum>;
// @deprecated: use SubscriptionPlanTierEnum instead
export type UserSubscriptionTier = z.infer<typeof SubscriptionPlanTierEnum>;
export type UserAccountStatus = z.infer<typeof UserAccountStatusEnum>;

const DropboxSignSchema = z.object({
  clientId: z.string().optional(),
});

const CustomDomainsSchema = z.object({
  domainName: z.string().optional(),
  rootDomainName: z.string().optional(),
  subdomainName: z.string().optional(),
  useCustomDomain: z.boolean().optional(),
});

const UserSubscriptionSchema = z.object({
  status: UserSubscriptionStatusEnum,
  tier: SubscriptionPlanTierEnum,
  paymentFailedDate: z.date().nullable().optional(),
});

export const UserOnboardingRequestSchema = z.object({
  completedGoFlowProfileForm: z.boolean().nullable().optional(),
  completedGoFlowCollectionForm: z.boolean().nullable().optional(),
  completedGoFlowTimeToShine: z.boolean().nullable().optional(),
  seenVideos: z.array(z.string()).nullable().optional(),
});

export type UserOnboardingRequestType = z.infer<
  typeof UserOnboardingRequestSchema
>;

export const UserOnboardingSchema = z.object({
  completedGoFlowProfileForm: z.date().nullable().optional(),
  completedGoFlowCollectionForm: z.date().nullable().optional(),
  completedGoFlowTimeToShine: z.date().nullable().optional(),
  seenVideos: z.array(z.string()).optional(),
});

export type UserOnboardingType = z.infer<typeof UserOnboardingSchema>;

const UpgradeProFeatureSchema = z.object({
  seenUpgradeDate: z.date(),
  seenUpgradeCount: z.number(),
});

export const UserUpgradeProRequestSchema = z.object({
  customBranding: z.boolean().nullable().optional(),
  customDomain: z.boolean().nullable().optional(),
  digitalSignature: z.boolean().nullable().optional(),
  zapierIntegration: z.boolean().nullable().optional(),
});

export type UserUpgradeProRequestType = z.infer<
  typeof UserUpgradeProRequestSchema
>;

export const UserUpgradeProSchema = z.object({
  customBranding: UpgradeProFeatureSchema.nullable().optional(),
  customDomain: UpgradeProFeatureSchema.nullable().optional(),
  digitalSignature: UpgradeProFeatureSchema.nullable().optional(),
  zapierIntegration: UpgradeProFeatureSchema.nullable().optional(),
});

export type UserUpgradeProType = z.infer<typeof UserUpgradeProSchema>;

export const CustomFieldOptionsSchema = z.object({
  select: z.enum(["multi", "single"]),
  items: z.array(z.string()),
});

export type CustomFieldOptionsType = z.infer<typeof CustomFieldOptionsSchema>;

export const CustomFieldSchema = z.object({
  field: z.string(),
  icon: z.string(),
  required: z.boolean(),
  type: z.string(),
  options: CustomFieldOptionsSchema.optional(),
});

export const UserSchema = BaseSchema.extend({
  email: z.string().email(),
  avatarURL: z.string().nullable().optional(),
  pronouns: z.string().optional(),
  title: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  slug: z.string().optional(),
  testimonials: z.array(TestimonialSchema.optional()).optional(),
  publicSchedulerIds: ArtifactIdsOptionsSchema.optional(),
  publicPackageIds: ArtifactIdsOptionsSchema.optional(),
  memberAccountIds: z.array(z.string()).optional(),
  coachLocation: z.string().optional(),
  bio: z.string().optional(),
  companyName: z.string().optional(),
  companyLogo: z
    .object({
      url: z.string(),
      fileName: z.string(),
      size: z.number(),
      contentType: z.string(),
      fullPath: z.string().optional(),
      bucket: z.string().optional(),
      extension: z.string().optional(),
    })
    .nullable()
    .optional(),
  website: z.string().optional(),
  socialLinks: z
    .object({
      linkedin: z.string(),
      instagram: z.string(),
      twitter: z.string(),
    })
    .optional(),
  showLoginButton: z.boolean().optional(),
  showCompanyLogo: z.boolean().optional(),
  color: z
    .object({
      background: z.string(),
      foreground: z.string(),
      accent: z.string(),
    })
    .optional()
    .nullable(),
  font: UserFontEnum.optional(),
  stripe: z
    .object({
      stripe_user_id: z.string().optional(),
      stripe_customer_id: z.string().optional(),
    })
    .optional(),
  domains: CustomDomainsSchema.optional().nullable(),
  invoiceCustomization: UserInvoiceCustomizationSchema.optional().nullable(),
  integrations: z
    .object({
      calendars: z.array(z.any()).optional(),
      emails: z
        .object({
          emails: z.array(z.string().email()).optional(),
          list: z.array(z.any()).optional(),
        })
        .optional(),
    })
    .optional(),
  calendarWatch: z
    .object({
      expiration: z.date().nullable(),
      id: z.string().optional(),
      resourceId: z.string().optional(),
      resourceIdMap: z.any().optional(),
    })
    .optional(),
  FCMToken: z.string().optional(),
  customFields: z.array(CustomFieldSchema).optional(),
  isCoach: z.boolean().optional(),
  isDynamicTheme: z.boolean().optional(),
  lastUsedCurrency: z.string().optional(),
  lastUsedTaxTypeId: z.string().optional(),
  phoneNumber: z.string().optional(),
  dashboard: z.any().optional(),
  dismissed: z.any().optional(),
  gcal: z.any().optional(),
  gcalServerToken: z.any().optional(),
  referralCode: z.string().optional(),
  subscriptionsSyncedAt: z.date().optional(),
  syncedAt: z.number().optional(),
  timeZone: z.string().optional(),
  uid: z.string().optional(),
  useProDomain: z.boolean().optional(),
  zoom: z.any().optional(),
  dropboxSign: DropboxSignSchema.optional(),
  coachOnboardedAt: z.date().optional(),
  admin: z.boolean().optional(),
  accountStatus: UserAccountStatusEnum.optional(),
  // @TODO: the subscriptiona and account properties should be required
  //        when the `WEB-6417` task is complete
  subscription: UserSubscriptionSchema.optional(),
  onboarding: UserOnboardingSchema.optional(),
  companyDetails: z
    .object({
      heroImage: z.string().optional().nullable(),
      showHero: z.boolean().optional(),
      heroTitle: z.string().optional(),
      companyLogoMark: z.string().optional().nullable(),
      metaTitle: z.string().optional(),
      favicon: z.string().optional().nullable(),
      metaDescription: z.string().optional(),
      openGraphImage: z.string().optional().nullable(),
    })
    .optional(),
  sectionTitles: z
    .object({
      packages: z.string().optional(),
      schedulers: z.string().optional(),
      members: z.string().optional(),
    })
    .optional(),
  brandId: z.string().optional(),
  orgLevelDirectClientAccess: z.boolean().optional(),
});

export type UserType = z.infer<typeof UserSchema>;
export type CustomDomainsType = z.infer<typeof CustomDomainsSchema>;
export type DropboxSignType = z.infer<typeof DropboxSignSchema>;

export const schemaProperties: SchemaProperties = {
  collectionName: "users",
  deleteMode: "hard",
};
