import {
  CalendarIntegration,
  IntegrationCalendarIntegration,
} from "@practice/sdk";
import { IntegrationsResponseType } from "api-services/definitions/integrations";

import { AppointmentType } from "@lib/data/schemas/appointment";

export const getMatchingLegacyIntegration = (
  calendars?: IntegrationsResponseType["calendars"],
  calendarId?: string,
  accountId?: string
) => {
  if (!calendarId || !calendars) return;

  const matchingAccounts = calendars?.filter((int) =>
    int.gcal.calendars.find((c: any) => c.id === calendarId)
  );

  if (accountId) {
    const integration = matchingAccounts.find(
      (int) => int.member.id === accountId
    );

    if (integration) return integration;
  }

  if (matchingAccounts?.length > 1) {
    const integration = matchingAccounts.find((int) =>
      int.gcal.calendars.find((c: any) => c.id === calendarId && c.primary)
    );

    if (integration) return integration;
  }

  return matchingAccounts?.[0];
};

// TODO This should be used only in the API
export const getCalendarOwner = (
  calendars?: IntegrationsResponseType["calendars"],
  calendarId?: string,
  accountId?: string
) => {
  return getMatchingLegacyIntegration(calendars, calendarId, accountId)?.member;
};

export const getMatchingIntegration = (
  calendars?: CalendarIntegration[],
  calendar?: {
    id?: string;
    _email?: string;
    integration?: IntegrationCalendarIntegration;
  }
) => {
  const calendarId = calendar?.id;
  const email = calendar?._email;
  const integration = calendar?.integration;
  if (!calendarId || !calendars) return;

  if (integration) {
    const int = calendars.find(
      (int) =>
        (int.integrationId &&
          int.integrationId === integration.integrationId) ||
        int.email === integration.email
    );
    if (int) return int;
  }

  const matchingAccounts = calendars?.filter((int) =>
    int.calendars.find((c) => c.id === calendarId)
  );

  if (email) {
    const int = matchingAccounts.find((int) => int.email === email);

    if (int) return int;
  }

  if (matchingAccounts?.length > 1) {
    const integration = matchingAccounts.find((int) =>
      int.calendars.find((c) => c.id === calendarId && c.isDefaultCalendar)
    );

    if (integration) return integration;
  }

  return matchingAccounts?.[0];
};

export const getAppointmentCalendar = (
  appointment: AppointmentType,
  calendars: CalendarIntegration[]
) => {
  if (!calendars?.length) return;

  const calendarId = appointment.calendarId || appointment.gcalCalendar?.id;

  const getIntegration = () => {
    if (appointment.integrationInfo) {
      const { integrationId, email } = appointment.integrationInfo;
      const matchingPerId = calendars.find(
        (int) => integrationId && int.integrationId === integrationId
      );
      if (matchingPerId) return matchingPerId;
      const matchingPerEmail = calendars.find((int) => int.email === email);
      if (matchingPerEmail) return matchingPerEmail;
    }

    return getMatchingIntegration(calendars, { id: calendarId })!;
  };

  const integration = getIntegration();

  return integration?.calendars?.find((c) => c.id === calendarId);
};
