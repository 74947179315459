import { DateTime } from "luxon";

import { ClientType } from "./data/schemas/client";
import { OrganizationType } from "./data/schemas/organization";

export const concealEmail = (email) => {
  const [username, domain] = email.split("@");
  const root = domain.substring(0, domain.lastIndexOf("."));
  const extension = domain.substr(domain.lastIndexOf("."));
  const visibleChars = username.length > 5 ? 3 : 1;

  return `${username.substr(0, visibleChars)}${username
    .replace(/./g, "*")
    .substr(visibleChars)}@${root.substr(0, 1)}${root
    .replace(/./g, "*")
    .substr(1)}${extension}`;
};

export const customJoin = (arr, s1, s2) =>
  arr
    .slice(0, -1)
    .join(s1)
    .concat(arr.length > 1 ? s2 : "", arr.slice(-1));

export const getLinkFromLocation = (location = "") => {
  location = location.trim();
  // naive way to detect URLs and deep links like whatsapp://
  if (RegExp(/^www/).test(location)) return `//${location}`;
  if (RegExp(/^(http|https|.+:\/\/).*/).test(location)) return location;
  // this should take care of most phone numbers: (remove -, (), whitespaces and + then check if string contains only numbers)
  if (RegExp(/^\d+$/).test(location.replace(/[-()\s+]/g, "")))
    return `tel:${location}`;
  // otherwise assume everything else is a street address
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    location
  )}`;
};

export const formatPrice = (
  total,
  currency,
  locale = "en-US",
  withPrefix = true
) => {
  const formattedTotal = (total / 100).toLocaleString(locale, {
    style: "currency",
    currencyDisplay: "symbol",
    currency: currency,
  });

  return "USD" === currency && withPrefix
    ? `USD ${formattedTotal}`
    : formattedTotal;
};

export const isDevOrPreviewEnvironment =
  process.env.PRACTICE_ENV !== "production";

export const getCurrentURIFromServer = () => {
  if (process.env.PRACTICE_ENV === "local") {
    return "http://localhost:3000";
  }

  return `https://${process.env.PRACTICE_APP_URL}`;
};

/**
 * Returns the URI for the CDash app from the correct environmnent
 * */
export const getCDashURI = () => {
  if (process.env.PRACTICE_ENV === "local") {
    return "http://localhost:3002";
  }

  if (isDevOrPreviewEnvironment) {
    return "https://cdash.app.practice.dev";
  } else {
    return "https://cdash.app.practice.do";
  }
};

export const getCurrentAPIServerURI = () => {
  if (process.env.PRACTICE_ENV === "local") {
    return "http://localhost:3004";
  }

  return `https://${process.env.PRACTICE_API_URL}`;
};

export const getCoachBaseUrl = (
  user: Pick<OrganizationType, "domains" | "slug" | "id">,
  ignoreCustomDomain?: boolean
) => {
  if (
    !ignoreCustomDomain &&
    user.domains &&
    user.domains.useCustomDomain &&
    user.domains.domainName
  ) {
    return `https://${user.domains.domainName}`;
  }

  const slug = typeof user === "string" ? user : user.slug || user.id;

  return `${getCurrentURIFromServer()}/me/${slug}`;
};

export const getContactEmails = (client: ClientType) => {
  if (client.emails) return client.emails;
  if (!client.email) return [];
  return [client.email];
};

export const stripDotsFromGmailEmail = (email: string) => {
  const [username, domain] = email.split("@");
  if (domain !== "gmail.com") return email;
  return `${username.replace(/\./g, "")}@${domain}`;
};

export const getClientEmailsForSync = (client: ClientType) => {
  const emails = getContactEmails(client);
  return emails.map(stripDotsFromGmailEmail);
};

export const translateAttendeeStatus = {
  accepted: "confirmed",
  tentative: "maybe",
  needsAction: "pending",
  declined: "declined",
};

// Create blob + simulate link click to download csv file
export const downloadCSVFile = (csvText, filename = "Practice.csv") => {
  const blob = new Blob([csvText], { type: "text/csv" });

  downloadFile(blob, filename);
};

export const downloadFromUrl = async (url, filename) => {
  const res = await fetch(url);
  const blob = await res.blob();

  downloadFile(blob, filename);
};

const downloadFile = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;

  // Simulate a click to download the csv file created above. Not sure
  // why this seems to be the accepted way to download a file
  a.click();

  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 500);
};

export const getThumbnailFromUrl = (url, size = 200) =>
  `https://thumbnails.cloud/v1/jpg?token=AzZdN8SV2maWmvJh&url=${encodeURIComponent(
    url
  )}&size=${size}&mode=orig`;

// dates
export const timestampToDateTime = (ts) => DateTime.fromJSDate(ts.toDate());

export const sortAlphabetically = (labels) => {
  return labels.sort((a, b) => {
    if (a.title.toLowerCase() < b.title.toLowerCase()) {
      return -1;
    }
    if (a.title.toLowerCase() > b.title.toLowerCase()) {
      return 1;
    }
    return 0;
  });
};

export const extractUrlsFromText = (text) => {
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return text.match(urlRegex);
};

export const stringifyError = (error) =>
  JSON.stringify(error, Object.getOwnPropertyNames(error));

export function removeUndefinedsFromObject(object) {
  Object.entries(object).forEach(([key, value]) => {
    if (value && typeof value === "object") removeUndefinedsFromObject(value);
    if (value === undefined) {
      delete object[key];
    }
  });
  return object;
}

/**
 * Return a number in ordinal form. Ex: 1st, 2nd, 3rd, 4th, etc.
 * */
export const getOrdinal = (value: number) => {
  const suffix = ["th", "st", "nd", "rd"];
  const newValue = value % 100;
  return (
    value + (suffix[(newValue - 20) % 10] || suffix[newValue] || suffix[0])
  );
};

export const cleanUpUrl = () => {
  window?.history?.replaceState(null, "", window?.location?.pathname || "/");
};

/**
 *  Return the formated URL for support
 *  @param {string} email - The user email who is requesting support
 *  @param {string} type - The type of support requested
 * */
export const getSupportUrl = (
  email: string,
  type: string,
  subject?: string,
  description?: string
) =>
  `https://help.practice.do/hc/en-us/requests/new?tf_anonymous_requester_email=${email}&tf_16924183395604=${type}${
    subject ? `&tf_subject=${subject}` : ""
  }${description ? `&tf_description=${description}` : ""}`;

/**
 * Useful to always print the same output when using JSON.stringify,
 * especially when using it in useEffect dependencies.
 *
 * e.g. JSON.stringify(coach?.customFields ?? {}, replacer)
 */
export const replacer = (key, value) =>
  value instanceof Object && !(value instanceof Array)
    ? Object.keys(value)
        .sort()
        .reduce((sorted, key) => {
          sorted[key] = value[key];
          return sorted;
        }, {})
    : value;
