import { AnyZodObject, z } from "zod";

import { MakeOptional } from "@lib/shared-types";

export const BaseSchema = z.object({
  id: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().optional(),
});

type BaseSchemaType = z.infer<typeof BaseSchema>;

export type RepositoryCalculatedFields = keyof BaseSchemaType;

export type CreatePayload<T extends AnyZodObject> = MakeOptional<
  z.infer<T>,
  RepositoryCalculatedFields
>;
